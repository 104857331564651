.introtextContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 100px 80px 0 80px; 
    margin: 90px 0 40px 0; 
    flex-wrap: wrap;
}

.introtext {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 300px;
    font-family: 'Arial', sans-serif;
}

.typeEffect {
    display: flex;
    height: 90px;
    align-items: center;
    color:    rgb(75, 4, 4);
   /* color: rgb(45, 73, 112);*/
}

.introtext h1 {
    font-size: 60px;
    margin-bottom: 0px;
}

.introtext h2 {
    font-size: 40px;
    margin-top: 0;
    padding-top: 18px;
    line-height: 60px;
}

.image {
    width: 20%;
    z-index: 1;
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    border-radius: 0;
    border: solid var(--color-dark) 6px;
    box-shadow: 0 0 4px 0 rgb(0, 0, 0);
    margin-left: 20px;
}

.name{
    color:  rgb(75, 4, 4);
}

/* Animation for Hero Image */
@keyframes floating {
    0% {
        transform: translate(0, 0px);
    }
    50% {
        transform: translate(0, 10px);
    }
    100% {
        transform: translate(0, 0px);
    }
}

/* Media Queries for smaller screens */
/* Media Queries for smaller screens */
@media (max-width: 768px) {
    .introtextContainer {
        flex-direction: column; /* Stack items vertically on smaller screens */
        align-items: center;
        padding: 20px 20px 0 20px;
    }

    .introtext {
        margin-right: 0;
        padding-right: 0;
        max-width: 100%; /* Allow text to take full width on small screens */
        text-align: center; /* Center text on smaller screens */
    }

    .introtext h1 {
        font-size: 40px;
    }

    .introtext h2 {
        font-size: 24px;
        line-height: 36px;
    }

    .typeEffect {
        height: auto;
        justify-content: center; /* Center the typewriter effect */
    }

    .image {
        width: 80%;
        margin-left: 0;
    }
}

@media (max-width: 480px) {
    .introtextContainer {
        flex-direction: column;
        align-items: center;
        padding: 10px 10px 0 10px;
        margin-top: 60px;
    }

    .introtext {
        margin-right: 0;
        padding-right: 0;
        max-width: 100%;
        text-align: center;
    }

    .introtext h1 {
        font-size: 30px;
    }

    .introtext h2 {
        font-size: 20px;
        line-height: 30px;
    }

    .typeEffect {
        height: auto;
        justify-content: center; /* Center the typewriter effect */
    }

    .image {
        margin-top: 20px;
        width: 80%;
        height: auto;
        margin-left: 0;
    }
}
