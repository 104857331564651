body{
  margin: 0;
  padding: 0;
  background: linear-gradient(to bottom, #cf8686, #d30000);
  background: linear-gradient(to bottom, #86b1cf, #000bd3);
  background: radial-gradient(circle at 5% 16%, rgba(255, 255, 255, 0.614), transparent 23%),
                radial-gradient(circle at 95% 16%, rgba(255, 255, 255, 0.614 ), transparent 23%),
                radial-gradient(circle at 50% 80%, rgba(255, 255, 255, 0.578), transparent 20%),
                #830505; /* Darker base background color */
 
}

/*background: linear-gradient(to bottom, #86b1cf, #000bd3);
  background: radial-gradient(circle at 10% 20%, rgba(255, 255, 255, 0.9), transparent 20%),
                radial-gradient(circle at 80% 30%, rgba(255, 255, 255, 0.7), transparent 25%),
                radial-gradient(circle at 50% 80%, rgba(255, 255, 255, 0.8), transparent 20%),
                #99c3da; /* Darker base background color */
