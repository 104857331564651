.about {
    padding: 0;
    margin: 0;
    margin-bottom: 50px;
    padding-bottom: 50px;
}

.high {
    color: rgb(207, 75, 75);
}

.line {
    width: 100%;
    height: 2px; /* Line thickness */
    background-color: #ccc; /* Line color */
    margin-bottom: 0px; /* Space between the line and the first row */
}

.row {
    display: flex;
    align-items: stretch; /* Make items stretch to the same height */
    justify-content: space-between;
    margin: 0;
    width: 100%;
    flex-wrap: nowrap; /* Prevent wrapping */
}

.gif {
    flex: 0 0 60%; /* Occupy 60% of the row’s width */
    height: auto;
    width: 60%;
    max-width: 60%;
    border-radius: 0px;
    margin: 0;
    border: 5px solid var(--color-dark); /* Add a border */
    box-shadow: 0 2px 5px rgba(139, 180, 186, 0.537); /* Add a shadow */
}

.text {
    flex: 1; /* Occupy the remaining space */
    padding: 20px;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center text vertically */
    background: rgba(178, 58, 58, 0.8); /* Background color */
    border: 2px solid rgb(124, 34, 34); /* Border color */
    border-radius: 0px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow */
    background: radial-gradient(circle at 5% 5%, rgba(255, 255, 255, 0.212), transparent 35%),
                radial-gradient(circle at 95% 95%, rgba(255, 255, 255, 0.212), transparent 30%), 
                 #961919;
}

.text h2 {
    margin-bottom: 10px;
    font-size: 39px;
}

.text p {
    line-height: 1.6;
    font-size: 25px;
}

/* Media Queries for smaller screens */
@media (max-width: 768px) {
    .row {
        flex-direction: row; /* Keep row layout */
        align-items: center;
        justify-content: space-between;
    }

    .gif {
        width: 60%; /* Keep the GIF width same as desktop */
        margin: 10px 0;
    }

    .text {
        width: 40%; /* Keep the text width same as desktop */
        padding: 10px;
    }

    .text h2 {
        font-size: 28px;
    }

    .text p {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .row {
        flex-direction: row; /* Keep row layout */
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .gif {
        width: 60%; /* Keep the GIF width same as desktop */
        margin: 10px 0;
    }

    .text {
        width: 40%; /* Keep the text width same as desktop */
        padding: 10px;
    }

    .text h2 {
        font-size: 20px; /* Adjust font size for smaller screens */
    }

    .text p {
        font-size: 14px; /* Adjust font size for smaller screens */
    }
}

