.hero {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-size: cover;
}

.header {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    z-index: 2;
}

.menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 11px;
}

.menuBtn {
    display: block;
    cursor: pointer;
    width: 50px;
    height: 50px;
}

.menuItems {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 13px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(5px);
    padding: 24px 33px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    max-height: 0; /* Initially collapsed */
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0;
    list-style: none;
}

.menuOpen {
    max-height: 300px; /* Adjust according to your content */
    opacity: 1;
}

.menuItem {
    text-decoration: none;
    color: #000; /* Replace with your desired color */
    font-weight: 1000;
}

.navLink {
    text-decoration: none;
    color: inherit;
}

.heroContent {
    text-align: center;
    font-weight: bold;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    padding: 20px 40px;
    border-radius: 10px;
    z-index: 1;
}

.heroTitle {
    font-size: 56px;
    color: #000;
    margin: 0;
}

.scrollButton {
    margin-top: 20px;
    background: none;
    border: none;
    font-size: 48px;
    cursor: pointer;
    color: #000;
}
